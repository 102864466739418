import { graphql } from "gatsby"
import React, {useState, useEffect} from "react"
import Cards from "../components/Cards"
import Layout from "../layouts/Layout"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const TagPage = ({ pageContext, data }) => {
  const { tag } = pageContext
  const allPosts = data.post.nodes
  const [list, setList] = useState([...allPosts.slice(0, 9)])
  const [loadMore, setLoadMore] = useState(false)  
  const [hasMore, setHasMore] = useState(allPosts.length > 9)

 const handleLoadMore = () => {
    setLoadMore(true)
  }

  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length
      const isMore = currentLength < allPosts.length
      const nextResults = isMore
        ? allPosts.slice(currentLength, currentLength + 3)
        : []
      setList([...list, ...nextResults])
      setLoadMore(false)
    }
  }, [loadMore, hasMore, setList, allPosts, list]) 

  useEffect(() => {
    const isMore = list.length < allPosts.length
    setHasMore(isMore)
  }, [list, allPosts]) 

  return (
    <Layout>
      <SiteMetadata
        title="Tag"
        description="VeganBlender - Plant based recipes using your blender"
      />
      <div className="container mt-8 max-w-screen-lg mx-auto grid grid-cols-1 sm:grid-cols-8 sm:px-8 sm:py-12 sm:gap-x-8 md:py-8">
        <div className="relative col-span-1">
          <h2 className="text-sm font-medium sm:mb-1 sm:text-gray-500">
            Post Tags
          </h2>
          <ul className="recipe-tags list-unstyled flex flex-row md:flex-col flex-wrap">
            <li className={`${tag === "flour" ? "font-semibold" : ""} mb-0 pb-0 pt:0 pr-2 md:pb-2 md:pt-2`}>
              <a className="portfolio-item" href="/tags/flour">
                flour
                <span className="d-none d-sm-inline-block"></span>
              </a>
            </li>
            <li className={`${tag === "nut butter" ? "font-semibold" : ""} mb-0 pb-0 pt:0 pr-2 md:pb-2 md:pt-2`}>
              <a className="portfolio-item" href="/tags/nut-butter">
                nut butter
                <span className="d-none d-sm-inline-block"></span>
              </a>
            </li>
            <li className={`${tag === "review" ? "font-semibold" : ""} mb-0 pb-0 pt:0 pr-2 md:pb-2 md:pt-2`}>
              <a className="portfolio-item" href="/tags/review">
                review
                <span className="d-none d-sm-inline-block"></span>
              </a>
            </li>
            <li className={`${tag === "sauce" ? "font-semibold" : ""} mb-0 pb-0 pt:0 pr-2 md:pb-2 md:pt-2`}>
              <a className="portfolio-item" href="/tags/sauce">
                sauce
                <span className="d-none d-sm-inline-block"></span>
              </a>
            </li>
            <li className={`${tag === "cashew cream" ? "font-semibold" : ""} mb-0 pb-0 pt:0 pr-2 md:pb-2 md:pt-2`}>
              <a className="portfolio-item" href="/tags/cashew-cream">
                cashew cream
                <span className="d-none d-sm-inline-block"></span>
              </a>
            </li>
            <li className={`${tag === "cheese" ? "font-semibold" : ""} mb-0 pb-0 pt:0 pr-2 md:pb-2 md:pt-2`}>
              <a className="portfolio-item" href="/tags/cheese">
                cheese
                <span className="d-none d-sm-inline-block"></span>
              </a>
            </li>
          </ul>
        </div>
        <div className="mt-8 md:mt-0 col-span-7">
          <h1 className="text-2xl">{`Tagged with ${tag}`}</h1>
          <div className="w-full">
            {data.post && data.post.nodes.length > 0 ? (
              <Cards hasMore={hasMore} onClick={handleLoadMore} items={list} />
            ) : (
              <p>No results found</p>
            )}
          </div>
        </div>
      </div>
      <Newsletter />
    </Layout>
  )
}

export default TagPage

export const query = graphql`
  query TagQuery($tag: String!){
    post: allContentfulPost(filter:{tags:{eq:$tag}}) {
      nodes {
        ...PostCard
      }
    }
  }
`
